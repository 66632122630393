@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-ExtraLight.eot");
  src: local("Poppins ExtraLight"), local("Poppins-ExtraLight"),
    url("fonts/Poppins-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-ExtraLight.woff2") format("woff2"), url("fonts/Poppins-ExtraLight.woff") format("woff"),
    url("fonts/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Medium.eot");
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url("fonts/Poppins-Medium.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-Medium.woff2") format("woff2"), url("fonts/Poppins-Medium.woff") format("woff"),
    url("fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-LightItalic.eot");
  src: local("Poppins Light Italic"), local("Poppins-LightItalic"),
    url("fonts/Poppins-LightItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-LightItalic.woff2") format("woff2"), url("fonts/Poppins-LightItalic.woff") format("woff"),
    url("fonts/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-ExtraLightItalic.eot");
  src: local("Poppins ExtraLight Italic"), local("Poppins-ExtraLightItalic"),
    url("fonts/Poppins-ExtraLightItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-ExtraLightItalic.woff2") format("woff2"),
    url("fonts/Poppins-ExtraLightItalic.woff") format("woff"),
    url("fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Regular.eot");
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("fonts/Poppins-Regular.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-Regular.woff2") format("woff2"), url("fonts/Poppins-Regular.woff") format("woff"),
    url("fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-SemiBold.eot");
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
    url("fonts/Poppins-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-SemiBold.woff2") format("woff2"), url("fonts/Poppins-SemiBold.woff") format("woff"),
    url("fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Black.eot");
  src: local("Poppins Black"), local("Poppins-Black"), url("fonts/Poppins-Black.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-Black.woff2") format("woff2"), url("fonts/Poppins-Black.woff") format("woff"),
    url("fonts/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-ExtraBoldItalic.eot");
  src: local("Poppins ExtraBold Italic"), local("Poppins-ExtraBoldItalic"),
    url("fonts/Poppins-ExtraBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-ExtraBoldItalic.woff2") format("woff2"), url("fonts/Poppins-ExtraBoldItalic.woff") format("woff"),
    url("fonts/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-MediumItalic.eot");
  src: local("Poppins Medium Italic"), local("Poppins-MediumItalic"),
    url("fonts/Poppins-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-MediumItalic.woff2") format("woff2"), url("fonts/Poppins-MediumItalic.woff") format("woff"),
    url("fonts/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Light.eot");
  src: local("Poppins Light"), local("Poppins-Light"), url("fonts/Poppins-Light.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-Light.woff2") format("woff2"), url("fonts/Poppins-Light.woff") format("woff"),
    url("fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Bold.eot");
  src: local("Poppins Bold"), local("Poppins-Bold"), url("fonts/Poppins-Bold.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-Bold.woff2") format("woff2"), url("fonts/Poppins-Bold.woff") format("woff"),
    url("fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Thin.eot");
  src: local("Poppins Thin"), local("Poppins-Thin"), url("fonts/Poppins-Thin.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-Thin.woff2") format("woff2"), url("fonts/Poppins-Thin.woff") format("woff"),
    url("fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-Italic.eot");
  src: local("Poppins Italic"), local("Poppins-Italic"),
    url("fonts/Poppins-Italic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-Italic.woff2") format("woff2"), url("fonts/Poppins-Italic.woff") format("woff"),
    url("fonts/Poppins-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-ThinItalic.eot");
  src: local("Poppins Thin Italic"), local("Poppins-ThinItalic"),
    url("fonts/Poppins-ThinItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-ThinItalic.woff2") format("woff2"), url("fonts/Poppins-ThinItalic.woff") format("woff"),
    url("fonts/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-BlackItalic.eot");
  src: local("Poppins Black Italic"), local("Poppins-BlackItalic"),
    url("fonts/Poppins-BlackItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-BlackItalic.woff2") format("woff2"), url("fonts/Poppins-BlackItalic.woff") format("woff"),
    url("fonts/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-BoldItalic.eot");
  src: local("Poppins Bold Italic"), local("Poppins-BoldItalic"),
    url("fonts/Poppins-BoldItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-BoldItalic.woff2") format("woff2"), url("fonts/Poppins-BoldItalic.woff") format("woff"),
    url("fonts/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-SemiBoldItalic.eot");
  src: local("Poppins SemiBold Italic"), local("Poppins-SemiBoldItalic"),
    url("fonts/Poppins-SemiBoldItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-SemiBoldItalic.woff2") format("woff2"), url("fonts/Poppins-SemiBoldItalic.woff") format("woff"),
    url("fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: url("fonts/Poppins-ExtraBold.eot");
  src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"),
    url("fonts/Poppins-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("fonts/Poppins-ExtraBold.woff2") format("woff2"), url("fonts/Poppins-ExtraBold.woff") format("woff"),
    url("fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9f9f9;
}

/** 
  Custom general
*/
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.scaleopsOrbitAnimation {
  -webkit-animation: orbit-animation 6s linear infinite;
  animation: orbit-animation 6s linear infinite;
}

.scaleopsShadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.scaleopsTextShadow {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
}

@keyframes orbit-animation {
  0% {
    transform: translate(-50%, -50%) rotate(0deg) translate(-30px, 0px);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg) translate(-30px, 0px);
  }
}

.scaleopsOrbitAnimation2 {
  -webkit-animation: orbit-animation2 10s linear infinite;
  animation: orbit-animation2 10s linear infinite;
}

@keyframes orbit-animation2 {
  0% {
    transform: translate(50%, 50%) rotate(360deg) translate(-30px, 0px);
  }
  100% {
    transform: translate(50%, 50%) rotate(0deg) translate(-30px, 0px);
  }
}

.red-background-row {
  background-color: #fffdf7;
}

/**
  fade-in-and-out animation
*/

@keyframes fade-in-and-out {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in-and-out {
  animation: fade-in-and-out 3s linear infinite;
}

/**
 * ==============================================
 * Dot Elastic
 * ==============================================
 */
.dot-elastic {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #c6c6d6;
  color: #c6c6d6;
  animation: dot-elastic 1s infinite linear;
}
.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic::before {
  left: -10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #c6c6d6;
  color: #c6c6d6;
  animation: dot-elastic-before 1s infinite linear;
}
.dot-elastic::after {
  left: 10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #c6c6d6;
  color: #c6c6d6;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

/**
 * ==============================================
 * custom ping animation
 * ==============================================
 */

.custom-animate-ping {
  animation: custom-animate-ping-animation 2s cubic-bezier(0, 0, 0.2, 1) infinite;
}

@keyframes custom-animate-ping-animation {
  75%,
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

/**
 * ==============================================
 * Info icon ping
 * ==============================================
 */

.info-icon-animation {
  animation: info-icon-animation 2s cubic-bezier(0, 0, 0.2, 1) 1;
}

@keyframes info-icon-animation {
  /* settle animation to make the icon move a bit */
  /* rotate slightly */
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
}

/**
 * ==============================================
 * General
 * ==============================================
 */

.unselectable-svg-text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.temporary-bounce {
  -webkit-animation-iteration-count: 1.5;
  animation-iteration-count: 1.5;
}

.rtl {
  direction: rtl;
}

/**
* box show glow animation
*/

@keyframes green-box-glow {
  0% {
    box-shadow: 0 0 5px 1px #52d39d;
  }
  50% {
    box-shadow: 0 0 10px 1px #52d39d;
  }
  100% {
    box-shadow: 0 0 5px 1px #52d39d;
  }
}
